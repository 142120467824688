.footer {
  &-tab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8em;
    background-color: lightgrey;
    box-shadow: 0px 10px 7px 7px black;
  }

  &-tabs {
    font-family: "Futura Bold", sans-serif;
    font-variant-caps: small-caps;
    font-size: 3em;

    > button {
      margin: 0;
      padding: 1em;
      border: none;
      background: none;
      font: inherit;
      cursor: pointer;
      outline: none;
    }
  }

  &-tab-active {
    font-family: "Futura Bold", sans-serif;
    font-variant-caps: small-caps;
    font-size: 3em;

    background-color: #035980;
    border-radius: 10px;
    color: lightgrey;

    > button {
      margin: 0;
      padding: 1em;
      color: lightgrey;
      border: none;
      background: none;
      font: inherit;
      cursor: pointer;
      outline: none;
    }
  }
}
