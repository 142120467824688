@import "./src/components/list/EventList.scss";
@import "./src/components/details/EventTile.scss";
@import "./src/components/header/Menu.scss";
@import "./src/components/footer/Footer.scss";

@font-face {
  font-family: 'Futura Bold';
  src: url('../assets/fonts/Futura\ Bold.otf') format('opentype');
}

* {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

html,
body {
  background-color: #262626;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}