.menu {
  &-accordion-wrapper {
    position: sticky;
    top: 0;
    box-shadow: 0px -10px 7px 7px black;
    z-index: 1000;
    height: 5em;
  }

  &-contact-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.7em;
    font-size: 2em;
    background: lightgray;
    font-style: none;
    border: none;
    width: 98vw;
    padding: 1vw;
  }

  &-accordion-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: lightgrey;
    height: 8em;
    padding: 0 2em;
  }

  &-accordion-drawer {
    background-color: lightgrey;
  }

  &-accordian-heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-accordion-heading {
    font-family: "Futura Bold", sans-serif;
    font-size: 3em;
    color: #035980;
  }

  &-accordion-svg {
    font-size: 1.3rem;
    transition: transform 0.3s ease;
  }

  &-favorites-svg {
    width: 1em;
    height: 1em;
    font-size: 6rem;
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: pointer;
  }

  &-filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-filter-past-button {
    width: fit-content;
    font-size: 2.5em;
    padding: 0.2em 0.4em;
    border-radius: 0.4em;
    color: #035980;
    font-family: Futura, sans-serif;
  }

  &-filter-past-button:active {
    color: lightgray;
    background: #035980;
  }
  &-filter-past-button:after {
    background: lightgray;
    color: #035980;
  }
}

.expanded {
  transform: rotate(90deg);
}

.search-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5em;

  & > input {
    border: 1px solid black;
    border-radius: 20px;
    font-size: 3em;
    height: 2em;
    width: 75vw;
    padding: 0 0.5em;
  }
}
