.event-list {
  width: 100vw;
  padding-top: 3em;
  padding-bottom: 12em;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
}
