.event-tile {
  display: grid;
  grid-template-columns: 7em auto;
  grid-template-rows: 100%;

  border-radius: 12px;
  margin: 10px auto;
  padding: 1em;
  max-width: 90vw;
  height: auto;

  background-color: var(--area-color);
  opacity: 90%;

  text-wrap: wrap;
}

.event-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: wrap;
  max-width: 82vw;

  &-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 1.3em;
    padding-bottom: 1em;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: 500;
    font-size: 18;

    height: 4em;
  }

  &-title-left {
    font-size: 3em;
  }

  &-fav {
    font-size: 1.5rem;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 3em;
    font-weight: 400;

    min-height: 3em;
  }

  &-footer-area {
    border: black 3px solid;
    border-radius: 10px;
    padding: 0.3em;
    min-height: 1.3em;
    text-align: center;
    background-color: var(--area-color);
  }

  &-footer-where {
    max-width: 50%;
  }

  &-description {
    font-weight: 200;
    font-size: 2.7em;
    min-height: 3em;
    align-items: center;
    padding-top: 0.7em;
    padding-bottom: 0.4em;
  }

  &-description-more {
    font-size: 1em;
    border: none;
    background-color: var(--area-color);
    color: #035980;
  }

  &-favorites-svg {
    width: 1em;
    height: 1em;
    font-size: 2.5rem;
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: pointer;
  }
}

.favorited {
  fill: gold;
  color: gold;
  filter: drop-shadow(1px 2px 1px rgb(0 0 0 / 0.4));
}
