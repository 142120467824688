.event-list {
  flex-flow: column wrap;
  width: 100vw;
  padding-top: 3em;
  padding-bottom: 12em;
  display: flex;
  overflow-y: auto;
}

.event-tile {
  background-color: var(--area-color);
  opacity: .9;
  text-wrap: wrap;
  border-radius: 12px;
  grid-template-rows: 100%;
  grid-template-columns: 7em auto;
  max-width: 90vw;
  height: auto;
  margin: 10px auto;
  padding: 1em;
  display: grid;
}

.event-details {
  text-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  max-width: 82vw;
  display: flex;
}

.event-details-icons {
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1em;
  font-size: 1.3em;
  display: flex;
}

.event-details-title {
  justify-content: space-between;
  align-items: center;
  height: 4em;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.event-details-title-left {
  font-size: 3em;
}

.event-details-fav {
  font-size: 1.5rem;
}

.event-details-footer {
  justify-content: space-between;
  align-items: center;
  min-height: 3em;
  font-size: 3em;
  font-weight: 400;
  display: flex;
}

.event-details-footer-area {
  text-align: center;
  background-color: var(--area-color);
  border: 3px solid #000;
  border-radius: 10px;
  min-height: 1.3em;
  padding: .3em;
}

.event-details-footer-where {
  max-width: 50%;
}

.event-details-description {
  align-items: center;
  min-height: 3em;
  padding-top: .7em;
  padding-bottom: .4em;
  font-size: 2.7em;
  font-weight: 200;
}

.event-details-description-more {
  background-color: var(--area-color);
  color: #035980;
  border: none;
  font-size: 1em;
}

.event-details-favorites-svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
  width: 1em;
  height: 1em;
  font-size: 2.5rem;
}

.favorited {
  fill: gold;
  color: gold;
  filter: drop-shadow(1px 2px 1px #0006);
}

.menu-accordion-wrapper {
  z-index: 1000;
  height: 5em;
  position: sticky;
  top: 0;
  box-shadow: 0 -10px 7px 7px #000;
}

.menu-contact-us-container {
  font-size: 2em;
  font-style: none;
  background: #d3d3d3;
  border: none;
  justify-content: space-between;
  align-items: center;
  width: 98vw;
  height: 1.7em;
  padding: 1vw;
  display: flex;
}

.menu-accordion-content {
  cursor: pointer;
  background-color: #d3d3d3;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 8em;
  padding: 0 2em;
  display: flex;
}

.menu-accordion-drawer {
  background-color: #d3d3d3;
}

.menu-accordian-heading-container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.menu-accordion-heading {
  color: #035980;
  font-family: Futura Bold, sans-serif;
  font-size: 3em;
}

.menu-accordion-svg {
  font-size: 1.3rem;
  transition: transform .3s;
}

.menu-favorites-svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
  width: 1em;
  height: 1em;
  font-size: 6rem;
}

.menu-filter-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.menu-filter-past-button {
  color: #035980;
  border-radius: .4em;
  width: fit-content;
  padding: .2em .4em;
  font-family: Futura, sans-serif;
  font-size: 2.5em;
}

.menu-filter-past-button:active {
  color: #d3d3d3;
  background: #035980;
}

.menu-filter-past-button:after {
  color: #035980;
  background: #d3d3d3;
}

.expanded {
  transform: rotate(90deg);
}

.search-input-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5em;
  display: flex;
}

.search-input-wrapper > input {
  border: 1px solid #000;
  border-radius: 20px;
  width: 75vw;
  height: 2em;
  padding: 0 .5em;
  font-size: 3em;
}

.footer-tab-wrapper {
  background-color: #d3d3d3;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 8em;
  display: flex;
  position: fixed;
  bottom: 0;
  box-shadow: 0 10px 7px 7px #000;
}

.footer-tabs {
  font-family: Futura Bold, sans-serif;
  font-size: 3em;
  font-variant-caps: small-caps;
}

.footer-tabs > button {
  font: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 1em;
}

.footer-tab-active {
  color: #d3d3d3;
  background-color: #035980;
  border-radius: 10px;
  font-family: Futura Bold, sans-serif;
  font-size: 3em;
  font-variant-caps: small-caps;
}

.footer-tab-active > button {
  color: #d3d3d3;
  font: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 1em;
}

@font-face {
  font-family: Futura Bold;
  src: url("Futura Bold.2013bcc4.otf") format("opentype");
}

* {
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
}

html, body {
  background-color: #262626;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}
/*# sourceMappingURL=index.f51d1f57.css.map */
